<template>
  <div class="float_window_wrap">
    <div class="close_btn">
      <button @click="close"><img src="@/static/error/Close.png"
             alt=""></button>
    </div>
    <div class="star">
      <img v-for="index of windowData.star"
           :key="index"
           src="@/static/error/star_a.png"
           alt="">
      <div v-if="windowData.star<5"
           style="display:inline-block">
        <img v-for="index of 5-windowData.star"
             :key="index"
             src="@/static/error/star.png"
             alt="">
      </div>
    </div>
    <div class="title">{{windowData.title}}</div>
    <div class="time">{{windowData.time}}</div>
    <div class="rateAndCount">
      <div class="rate">
        <div><span class="num">{{windowData.score}}</span><span v-show="Number(windowData.score)>=0"
                style="font-size:16rem;line-height:24rem;padding-left:4rem">%</span></div>
        <div class='txt'
             v-show="Number(windowData.score)>=0">得分率</div>
      </div>
      <div class="count">
        <div><span class="num">{{windowData.count}}</span></div>
        <div class='txt'>练习次数</div>
      </div>
    </div>
    <div class="button_wrap">
      <button v-if="windowData.type=='lecture'">
        <img src="@/static/error/讲义.png"
             alt=""
             title="讲义">
        <span>讲义</span>
      </button>
      <button @click="showVideo"
              v-show="windowData.video_list&&windowData.video_list.length != 0&&show_video">
        <div class="img"> <img src="@/static/error/视频.png"
               alt=""
               title="视频"> </div>
        <span>视频</span>

      </button>
      <button v-show="windowData.type=='knowledge'||windowData.type=='capability'"
              @click="doPaper">
        <div class="img"><img src="@/static/error/练习.png"
               alt=""
               title="练习"></div>

        <span>练习</span>
      </button>
    </div>
    <VideoDialog ref="videoRef" />
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import VideoDialog from './videoDialog.vue'
import { message } from 'ant-design-vue'
import { computed, onMounted } from "@vue/runtime-core";

let router = useRouter()
let windowData = ref({})
let emits = defineEmits(['close'])
const close = () => {
  emits('close')
}
let show_video = ref(null)
onMounted(() => {
  show_video.value = JSON.parse(window.localStorage.getItem('userInfo')).show_video
 
})
const doPaper = () => {
  let params
  console.log('type', windowData.value.type);
  
  if (windowData.value.type == 'knowledge') {
    params = {
      knowledge_id: windowData.value.knowledge_id,
    }
    window.sessionStorage.removeItem('testpaperCardIndex')
    window.sessionStorage.removeItem('paper')
    window.sessionStorage.removeItem('images')
    router.push('/doPaper?isKnowledge=1&form=' + JSON.stringify(params))
  }else if (windowData.value.type == 'capability') {
    params = {
      capability_id: windowData.value.capability_id,
    }
    window.sessionStorage.removeItem('testpaperCardIndex')
    window.sessionStorage.removeItem('paper')
    window.sessionStorage.removeItem('images')
    router.push('/doPaper?isCapability=1&form=' + JSON.stringify(params))
  }
}
let videoRef = ref()
const showVideo = () => {
  if (windowData.value.video_list.length == 0) {
    return message.warning('暂无视频')
  }
  videoRef.value.list = windowData.value.video_list
  videoRef.value.dialogVisible = true
}
defineExpose({ windowData })
</script>

<style lang="scss" scoped>
.float_window_wrap {
  width: 320rem;
  height: 290rem;
  background: #ffffff;
  padding: 24rem;
  box-shadow: 0rem 8rem 16rem 0rem rgba(0, 0, 0, 0.1);
  border-radius: 16rem;
  // font-family: PingFang SC-Regular, PingFang SC;
  .close_btn {
    position: absolute;
    right: 24rem;
    button {
      background: #fff;
      border: 0;
      cursor: pointer;
      width: 24rem;
      height: 24rem;
      padding: 0;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .star {
    line-height: 0;
    img {
      margin-right: 4rem;
      width: 16rem;
      height: 16rem;
    }
  }
  .title {
    margin-top: 4rem;
    margin-bottom: 2rem;
    font-size: 20rem;
    font-weight: 500;
    color: #333333;
    line-height: 30rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
  }
  .time {
    font-size: 16rem;
    font-weight: 400;
    color: #999999;
    line-height: 24rem;
  }
  .rateAndCount {
    display: flex;
    justify-content: space-between;
    margin-top: 16rem;
    margin-bottom: 16rem;
    & > div {
      width: 130rem;
      height: 98rem;
      border-radius: 8rem;
      padding: 16rem 0;
      text-align: center;
      background: #fafafa;
      color: #333;
    }
    .rate,
    .count {
      font-size: 16rem;
      .num {
        font-size: 28rem;
        font-weight: 500;
        line-height: 42rem;
      }
      .txt {
        font-weight: 400;
        line-height: 24rem;
        color: #999;
        font-size: 16rem;
      }
    }
  }
  .button_wrap {
    display: flex;
    justify-content: space-around;
    button {
      background: transparent;
      border: 0;
      padding: 6rem 12rem;
      flex: 1;
      display: flex;
      border-radius: 8rem;
      opacity: 1;
      border: 1rem solid #2196f3;
      font-size: 0;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      margin-right: 12rem;
      &:last-of-type {
        margin-right: 0;
      }
      .img {
        width: 24rem;
        height: 24rem;
      }
      img {
        width: 100%;
        height: 100%;
      }
      span {
        display: inline-block;
        font-size: 16rem;
        // font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #2196f3;
        padding-left: 2rem;
        height: 24rem;
        line-height: 24rem;
      }
    }
  }
}
</style>